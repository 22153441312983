import { motion } from "framer-motion";
import { useEffect, useState } from "react";

const FallingBaguettes = ({ isVisible }: { isVisible: boolean }) => {
  const [baguettes, setBaguettes] = useState<Array<{ id: number; x: number }>>(
    [],
  );

  useEffect(() => {
    if (isVisible) {
      const interval = setInterval(() => {
        setBaguettes((prev) => [
          ...prev,
          {
            id: Date.now(),
            x: Math.random() * window.innerWidth,
          },
        ]);
      }, 200);

      return () => clearInterval(interval);
    } else {
      setBaguettes([]);
    }
  }, [isVisible]);

  return (
    <div className="fixed inset-0 pointer-events-none z-40">
      {baguettes.map((baguette) => (
        <motion.img
          key={baguette.id}
          src="https://createsiteai.b-cdn.net/c/_/957a5d72-0995-421b-a972-2fee225caf65.webp"
          alt="Falling baguette"
          className="absolute w-16 h-16 object-contain"
          initial={{ y: -100, x: baguette.x, rotate: 0, opacity: 1 }}
          animate={{
            y: window.innerHeight + 100,
            rotate: 360,
            opacity: 0,
          }}
          transition={{ duration: 2, ease: "linear" }}
          onAnimationComplete={() => {
            setBaguettes((prev) => prev.filter((b) => b.id !== baguette.id));
          }}
        />
      ))}
    </div>
  );
};

export default FallingBaguettes;
