import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";
import RecipeStep from "./RecipeStep";
import RecipeIngredients from "./RecipeIngredients";

const RecipeSection = () => {
  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });

  return (
    <section className="w-full bg-[#FFFBF7] py-24" ref={ref}>
      <div className="container mx-auto px-4">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={inView ? { opacity: 1, y: 0 } : {}}
          transition={{ duration: 0.8 }}
          className="text-center mb-16"
        >
          <h2 className="font-serif desktop:text-5xl laptop:text-4xl tablet:text-3xl mobile:text-3xl text-[#2C1810] mb-6">
            Traditional Baguette Recipe
          </h2>
          <p className="text-[#5C4D44] desktop:text-xl laptop:text-lg tablet:text-base mobile:text-base max-w-2xl mx-auto">
            Master the art of French baking with our authentic baguette recipe,
            passed down through generations.
          </p>
        </motion.div>

        <RecipeIngredients />

        <div className="mt-16 space-y-24">
          <RecipeStep
            step={1}
            title="Mix the Ingredients"
            description="In a large bowl, combine flour, salt, and yeast. Gradually add water while mixing until a shaggy dough forms."
            imageUrl="https://createsiteai.b-cdn.net/c/_/154cb230-30bf-4331-baee-3ec6fde17620.webp"
          />
          <RecipeStep
            step={2}
            title="Initial Fermentation"
            description="Cover the dough and let it rest for 30 minutes at room temperature. This allows the flour to fully hydrate."
            imageUrl="https://createsiteai.b-cdn.net/c/_/3c3472cb-00c4-4314-897d-fc0526109945.webp"
          />
          <RecipeStep
            step={3}
            title="Shape the Baguettes"
            description="Divide the dough and shape into long, thin loaves. Place them on a floured baking sheet."
            imageUrl="https://createsiteai.b-cdn.net/c/_/fc41cc4d-8cd1-464b-b3ae-6984043688e7.webp"
          />
          <RecipeStep
            step={4}
            title="Final Proofing"
            description="Let the shaped baguettes proof for 1-2 hours until they've increased in size by about 50%."
            imageUrl="https://createsiteai.b-cdn.net/c/_/9b0beb16-8be4-4492-963e-b7ddff397227.webp"
          />
          <RecipeStep
            step={5}
            title="Bake to Perfection"
            description="Score the loaves and bake at 230°C (450°F) with steam for 20-25 minutes until golden brown."
            imageUrl="https://createsiteai.b-cdn.net/c/_/0f99edcf-219d-4ba6-af05-a347d66350ea.webp"
          />
        </div>
      </div>
    </section>
  );
};

export default RecipeSection;
