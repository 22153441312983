import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";

const ingredients = [
  { name: "Bread Flour", amount: "500g" },
  { name: "Water", amount: "350ml" },
  { name: "Salt", amount: "10g" },
  { name: "Active Dry Yeast", amount: "7g" },
];

const RecipeIngredients = () => {
  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });

  return (
    <div
      ref={ref}
      className="grid desktop:grid-cols-4 laptop:grid-cols-4 tablet:grid-cols-2 mobile:grid-cols-1 gap-6"
    >
      {ingredients.map((ingredient, index) => (
        <motion.div
          key={ingredient.name}
          initial={{ opacity: 0, y: 20 }}
          animate={inView ? { opacity: 1, y: 0 } : {}}
          transition={{ duration: 0.5, delay: index * 0.1 }}
          className="bg-white rounded-2xl p-6 shadow-lg border border-amber-50"
        >
          <h4 className="text-[#2C1810] font-medium text-lg mb-2">
            {ingredient.name}
          </h4>
          <p className="text-[#92400E] text-2xl font-serif">
            {ingredient.amount}
          </p>
        </motion.div>
      ))}
    </div>
  );
};

export default RecipeIngredients;
