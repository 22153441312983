import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";

interface RecipeStepProps {
  step: number;
  title: string;
  description: string;
  imageUrl: string;
}

const RecipeStep = ({
  step,
  title,
  description,
  imageUrl,
}: RecipeStepProps) => {
  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 0.2,
  });

  const isEven = step % 2 === 0;

  return (
    <div
      ref={ref}
      className="flex desktop:flex-row laptop:flex-row tablet:flex-col mobile:flex-col items-center gap-12"
    >
      <motion.div
        initial={{ opacity: 0, x: isEven ? 100 : -100 }}
        animate={inView ? { opacity: 1, x: 0 } : {}}
        transition={{ duration: 0.8, delay: 0.2 }}
        className={`desktop:w-1/2 laptop:w-1/2 tablet:w-full mobile:w-full ${
          isEven ? "desktop:order-2 laptop:order-2" : ""
        }`}
      >
        <div className="rounded-3xl overflow-hidden shadow-xl">
          <img
            src={imageUrl}
            alt={title}
            className="w-full h-[400px] object-cover filter saturate-[0.85] brightness-[1.1]"
          />
        </div>
      </motion.div>

      <motion.div
        initial={{ opacity: 0, x: isEven ? -100 : 100 }}
        animate={inView ? { opacity: 1, x: 0 } : {}}
        transition={{ duration: 0.8 }}
        className={`desktop:w-1/2 laptop:w-1/2 tablet:w-full mobile:w-full ${
          isEven ? "desktop:order-1 laptop:order-1" : ""
        }`}
      >
        <div className="inline-flex items-center gap-2 px-4 py-2 bg-[#F4E5D7] rounded-full mb-4">
          <span className="text-[#92400E] font-medium">Step {step}</span>
        </div>
        <h3 className="font-serif text-3xl text-[#2C1810] mb-4">{title}</h3>
        <p className="text-[#5C4D44] text-lg leading-relaxed">{description}</p>
      </motion.div>
    </div>
  );
};

export default RecipeStep;
