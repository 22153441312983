import { motion } from "framer-motion";
import { useState } from "react";
import FallingBaguettes from "./FallingBaguettes";
import { Link } from "react-router-dom";

const Hero = () => {
  const [showBaguettes, setShowBaguettes] = useState(false);
  return (
    <div className="relative w-full min-h-screen bg-[#FFFBF7] overflow-hidden">
      <div className="absolute inset-0 bg-[url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI1IiBoZWlnaHQ9IjUiPgo8cmVjdCB3aWR0aD0iNSIgaGVpZ2h0PSI1IiBmaWxsPSIjZmZmZmZmIj48L3JlY3Q+CjxyZWN0IHdpZHRoPSIxIiBoZWlnaHQ9IjEiIGZpbGw9IiNmYWYzZWIiPjwvcmVjdD4KPC9zdmc+')] opacity-30" />

      <div className="container mx-auto px-4 py-8">
        <div className="desktop:flex laptop:flex tablet:block mobile:block items-center desktop:gap-16 laptop:gap-12 tablet:gap-8 mobile:gap-8 min-h-[calc(100vh-4rem)]">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8 }}
            className="desktop:w-5/12 laptop:w-5/12 tablet:w-full mobile:w-full z-10"
          >
            <div className="inline-flex items-center gap-2 px-4 py-2 bg-[#F4E5D7] rounded-full mb-8">
              <svg
                className="w-4 h-4 text-[#D97706]"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z"
                />
              </svg>
              <span className="text-[#92400E] font-medium text-sm">
                Fresh batch ready at 7AM
              </span>
            </div>

            <h1 className="font-serif desktop:text-7xl laptop:text-6xl tablet:text-5xl mobile:text-4xl text-[#2C1810] leading-[1.1] mb-6">
              Artisanal
              <br />
              <span className="relative inline-block">
                Baguettes
                <motion.svg
                  initial={{ pathLength: 0 }}
                  animate={{ pathLength: 1 }}
                  transition={{ duration: 1, delay: 0.5 }}
                  className="absolute -bottom-3 left-0 w-full h-3 text-[#F59E0B]"
                  viewBox="0 0 100 8"
                  preserveAspectRatio="none"
                >
                  <path
                    d="M0,5 C30,2 70,2 100,5"
                    stroke="currentColor"
                    strokeWidth="3"
                    fill="none"
                  />
                </motion.svg>
              </span>
            </h1>

            <p className="text-[#5C4D44] desktop:text-xl laptop:text-lg tablet:text-base mobile:text-base mb-8 leading-relaxed max-w-md font-light">
              Handcrafted in our home kitchen using traditional French methods.
              Each baguette tells a story of warmth, love, and dedication to the
              perfect crust.
            </p>

            <div className="space-y-8">
              <div className="flex desktop:flex-row laptop:flex-row tablet:flex-col mobile:flex-col gap-4">
                <Link to="/order" className="w-fit">
                  <motion.button
                    whileHover={{ scale: 1.02 }}
                    whileTap={{ scale: 0.98 }}
                    className="bg-[#2C1810] text-[#FDF8F3] px-8 py-4 rounded-full font-medium hover:bg-[#3D261C] transition-colors shadow-lg shadow-amber-900/10"
                  >
                    Order for Today
                  </motion.button>
                </Link>

                <Link to="/menu" className="w-fit">
                  <motion.button
                    whileHover={{ scale: 1.02 }}
                    whileTap={{ scale: 0.98 }}
                    className="bg-[#FDF8F3] text-[#2C1810] px-8 py-4 rounded-full font-medium border-2 border-[#2C1810] hover:bg-[#2C1810] hover:text-[#FDF8F3] transition-colors"
                  >
                    Browse Menu
                  </motion.button>
                </Link>
              </div>

              <div className="flex items-center gap-6 bg-white/60 p-5 rounded-2xl backdrop-blur-sm border border-amber-50">
                <div className="flex -space-x-3">
                  {[...Array(4)].map((_, i) => (
                    <div
                      key={i}
                      className="w-12 h-12 rounded-full border-2 border-white overflow-hidden"
                    >
                      <img
                        src={`https://createsiteai.b-cdn.net/c/_/946f4443-5ac6-4f50-a33d-fa5f5979b5c4.webp${i === 0 ? "young+male" : i === 1 ? "middle_aged_female" : i === 2 ? "senior_male" : "young_female"}&style=realistic_image&size=1024x1024`}
                        alt="Happy customer"
                        className="w-full h-full object-cover filter saturate-[0.9]"
                      />
                    </div>
                  ))}
                </div>
                <div>
                  <div className="flex items-center gap-1 mb-1">
                    {[...Array(5)].map((_, i) => (
                      <svg
                        key={i}
                        className="w-4 h-4 text-amber-400"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                      >
                        <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                      </svg>
                    ))}
                  </div>
                  <p className="text-sm text-[#5C4D44]">
                    <span className="font-medium">4.9</span> from 200+ happy
                    customers
                  </p>
                </div>
              </div>
            </div>
          </motion.div>

          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8, delay: 0.2 }}
            className="desktop:w-7/12 laptop:w-7/12 tablet:w-full mobile:w-full desktop:mt-0 laptop:mt-0 tablet:mt-12 mobile:mt-12"
          >
            <div className="relative">
              <div className="grid grid-cols-12 gap-4">
                <motion.div
                  whileHover={{ scale: 1.02 }}
                  className="col-span-8 rounded-3xl overflow-hidden shadow-xl"
                  onMouseEnter={() => setShowBaguettes(true)}
                  onMouseLeave={() => setShowBaguettes(false)}
                >
                  <div className="relative">
                    <img
                      src="https://createsiteai.b-cdn.net/c/_/2f372b74-8d92-48d0-a4a7-b7bd7136b5ec.webp"
                      alt="Fresh baguettes"
                      className="w-full h-[500px] object-cover filter saturate-[0.85] brightness-[1.1]"
                    />
                    <div className="absolute inset-0 bg-gradient-to-t from-black/20 to-transparent" />
                  </div>
                </motion.div>

                <div className="col-span-4 space-y-4">
                  <motion.div
                    whileHover={{ scale: 1.02 }}
                    className="rounded-3xl overflow-hidden shadow-lg h-[240px]"
                  >
                    <img
                      src="https://createsiteai.b-cdn.net/c/_/12583bbe-152b-4448-ba44-1a66e101f6cd.webp"
                      alt="Baking process"
                      className="w-full h-full object-cover filter saturate-[0.85] brightness-[1.1]"
                    />
                  </motion.div>
                  <motion.div
                    whileHover={{ scale: 1.02 }}
                    className="rounded-3xl overflow-hidden shadow-lg h-[240px]"
                  >
                    <img
                      src="https://createsiteai.b-cdn.net/c/_/83e7b396-60d8-467c-b1d7-6aa21fd1e9c8.webp"
                      alt="Sliced baguette"
                      className="w-full h-full object-cover filter saturate-[0.85] brightness-[1.1]"
                    />
                  </motion.div>
                </div>
              </div>

              <motion.div
                initial={{ opacity: 0, scale: 0.8 }}
                animate={{ opacity: 1, scale: 1 }}
                transition={{ delay: 0.6 }}
                className="absolute -left-4 top-32 bg-white/95 rounded-2xl shadow-xl p-4 backdrop-blur-sm border border-amber-50"
              >
                <div className="flex items-center gap-3">
                  <div className="bg-amber-50 p-3 rounded-xl">
                    <svg
                      className="w-6 h-6 text-amber-700"
                      fill="none"
                      stroke="currentColor"
                      viewBox="0 0 24 24"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M21 15.546c-.523 0-1.046.151-1.5.454a2.704 2.704 0 01-3 0 2.704 2.704 0 00-3 0 2.704 2.704 0 01-3 0 2.704 2.704 0 00-3 0 2.704 2.704 0 01-3 0 2.701 2.701 0 00-1.5-.454M9 6v2m3-2v2m3-2v2M9 3h.01M12 3h.01M15 3h.01M21 21v-7a2 2 0 00-2-2H5a2 2 0 00-2 2v7h18zm-3-9v-2a2 2 0 00-2-2H8a2 2 0 00-2 2v2h12z"
                      />
                    </svg>
                  </div>
                  <div>
                    <p className="text-[#2C1810] font-medium">Made Fresh</p>
                    <p className="text-sm text-[#5C4D44]">Every Morning</p>
                  </div>
                </div>
              </motion.div>

              <motion.div
                initial={{ opacity: 0, scale: 0.8 }}
                animate={{ opacity: 1, scale: 1 }}
                transition={{ delay: 0.8 }}
                className="absolute -right-4 bottom-32 bg-white/95 rounded-2xl shadow-xl p-4 backdrop-blur-sm border border-amber-50"
              >
                <div className="flex items-center gap-3">
                  <div className="bg-amber-50 p-3 rounded-xl">
                    <svg
                      className="w-6 h-6 text-amber-700"
                      fill="none"
                      stroke="currentColor"
                      viewBox="0 0 24 24"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6"
                      />
                    </svg>
                  </div>
                  <div>
                    <p className="text-[#2C1810] font-medium">Home Baked</p>
                    <p className="text-sm text-[#5C4D44]">With Love</p>
                  </div>
                </div>
              </motion.div>
            </div>
          </motion.div>
        </div>
        <FallingBaguettes isVisible={showBaguettes} />
      </div>

      <style>
        {`
          .grain {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            opacity: 0.15;
            background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 256 256' xmlns='http://www.w3.org/2000/svg'%3E%3Cfilter id='noiseFilter'%3E%3CfeTurbulence type='fractalNoise' baseFrequency='0.65' numOctaves='3' stitchTiles='stitch'/%3E%3C/filter%3E%3Crect width='100%' height='100%' filter='url(%23noiseFilter)'/%3E%3C/svg%3E");
            pointer-events: none;
            mix-blend-mode: soft-light;
          }
        `}
      </style>
      <div className="grain" />
    </div>
  );
};

export default Hero;
