import { motion, useMotionValue, useSpring } from "framer-motion";
import { useEffect } from "react";

const CustomCursor = () => {
  const cursorX = useMotionValue(-100);
  const cursorY = useMotionValue(-100);

  const springConfig = { damping: 25, stiffness: 700 };
  const springX = useSpring(cursorX, springConfig);
  const springY = useSpring(cursorY, springConfig);

  useEffect(() => {
    const moveCursor = (e: MouseEvent) => {
      cursorX.set(e.clientX - 16);
      cursorY.set(e.clientY - 16);
    };

    window.addEventListener("mousemove", moveCursor);

    return () => {
      window.removeEventListener("mousemove", moveCursor);
    };
  }, []);

  return (
    <motion.div
      className="fixed pointer-events-none z-50 w-8 h-8"
      style={{
        x: springX,
        y: springY,
      }}
    >
      <img
        src="https://createsiteai.b-cdn.net/c/_/957a5d72-0995-421b-a972-2fee225caf65.webp"
        alt="Baguette cursor"
        className="w-full h-full object-contain filter drop-shadow-md"
      />
    </motion.div>
  );
};

export default CustomCursor;
